.signin-step1-container {
  min-width: 440px;
}

.signin-step1-username {
  margin: 1.4em 0;
}

.signin-step1-password {
  margin-bottom: 2em;
}

.signin-step1-button {
  width: 100%;
}