.signin-step2-container {
  min-width: 440px;
}

.signin-step2-otp-container {}

.signin-step2-otp-input {
  justify-content: center;
  width: 2.2rem !important;
  height: 2.8rem;
  margin: 1.4em 0.6em;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.signin-step2-button {
  width: 100%;
}